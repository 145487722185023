<script lang="ts">
    import { AppTile, Image } from '@nomodo/ui'

    import type { AppPage } from '$lib/types'

    export let classNames = ''
    export { classNames as class }
    export let apps: AppPage[]
</script>

<div
    class="app-tiles grid grid-cols-3 gap-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 {classNames}"
    data-cy="appTiles"
>
    {#each apps as app}
        <AppTile
            data-cy="appTileCta-{app.title}"
            href={`/${app.meta.site.slug}`}
        >
            <Image
                slot="image"
                class="block aspect-square overflow-hidden"
                alt={app.title}
                height={120}
                lazy
                src={`/w_160,f_auto,q_auto,fl_immutable_cache/${app.image}`}
                width={120}
            />
            <svelte:fragment slot="title">
                {app.title}
            </svelte:fragment>
        </AppTile>
    {/each}
</div>

<style global lang="postcss">
    .app-tiles {
        --gap-y: 1rem;
        --gap-x: 0.5rem;

        @media screen(md) {
            --gap-y: 1.5rem;
            --gap-x: 0.75rem;
        }
    }
</style>
